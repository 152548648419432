import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { dateTimeFormats } from '@/locale/dateFormat/format'
import { messages } from '@/locale/language/index.ts'

Vue.use(VueI18n)

const fallbackLocale = 'en'

type Locale = keyof typeof messages

function findCurrentLanguage(language: Locale): Locale {
  return Object.keys(messages).includes(language) ? language : undefined
}

export const i18n = new VueI18n({
  locale: findCurrentLanguage(navigator.language.split('-').shift() as Locale), // fetch and set browser language
  fallbackLocale, // set fallback locale
  messages, // set locale messages,
  dateTimeFormats, // set locale date format
  silentFallbackWarn: true, // shut up i18n in dev mode
})

export const getI18n = (locale: Locale) => {
  Vue.use(VueI18n)
  return new VueI18n({
    locale: findCurrentLanguage(locale), // fetch and set browser language
    fallbackLocale, // set fallback locale
    messages, // set locale messages,
    dateTimeFormats, // set locale date format
    silentFallbackWarn: true, // shut up i18n in dev mode
  })
}
