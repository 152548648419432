
<template>
  <div>
    <div>
      <img src="../assets/tenor.gif" width="100px" height="100px" />
    </div>
    <h1 class="logo">Hey, you're on matchicookies :)))</h1>
    <p>{{ $t('welcome-text') }}</p>
    <Button @click.native="goToDetailsPage(1)" title="Go to Details" />
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  name: 'Home',
  components: {
    Button,
  },
  methods: {
    goToDetailsPage: function (id) {
      this.$router.push('/details/' + id)
    },
  },
}
</script>

<style scoped lang="scss">
.logo {
  color: #ff8300;
  font-weight: bold;
}
</style>

