import axios from 'axios'
import { getCookie } from 'tiny-cookie'

let baseURL: string | null = process.env.VUE_APP_API_URL as string | null
let mothershipURL: string | null = process.env.VUE_APP_MOTHERSHIP_API_URL as string | null

export const setBaseUrl = (value: string): void => {
  baseURL = value
}

export const setMothershipUrl = (value: string): void => {
  mothershipURL = value
}

export const AUTH_TOKEN_COOKIE_NAME = '[YOUR_AUTH_TOKEN]'

export const api = axios.create()
export const mothershipApi = axios.create()

api.interceptors.request.use(
  (config) => {
    if (!baseURL)
      throw Error(
        '[matchicookies] baseURL is not defined, please initialize the plugin the Vue.use() method to specify it'
      )

    const token = getCookie(AUTH_TOKEN_COOKIE_NAME)

    config.baseURL = baseURL
    config.headers = {
      ...config.headers,
      'authorization-host': mothershipURL,
      authorization: `Token ${token}`,
    }

    return config
  },
  (error) => Promise.reject(error)
)

mothershipApi.interceptors.request.use(
  (config) => {
    if (!mothershipURL)
      throw Error(
        '[matchicookies] mothershipURL is not defined, please initialize the plugin the Vue.use() method to specify it'
      )

    const token = getCookie(AUTH_TOKEN_COOKIE_NAME)

    config.baseURL = mothershipURL
    config.headers = {
      ...config.headers,
      authorization: `Token ${token}`,
    }

    return config
  },
  (error) => Promise.reject(error)
)
