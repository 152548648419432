import './initCompositionApi'
import Vue from 'vue'
import Router from 'vue-router'
import { setBaseUrl, setMothershipUrl } from './interceptors'
import { VueConstructor } from 'vue/types/umd'
import App from './App.vue'
import router from './router'
import { i18n } from './plugins/i18n'
import '@sennder/plankton/dist/plankton.css'

if (document.getElementById('sennfront-app')) {
  Vue.config.productionTip = false
  Vue.use(Router)
  new Vue({
    router,
    i18n,
    render: (h) => h(App),
  }).$mount('#sennfront-app')
}

/**
 * Initializes app with the client app base URL
 */
export default {
  install: (
    _Vue: VueConstructor,
    { baseURL, mothershipURL }: { baseURL: string; mothershipURL: string }
  ): void => {
    setBaseUrl(baseURL)
    setMothershipUrl(mothershipURL)
  },
}
