
<template>
  <button class="button" @click="click">{{ title }}</button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    click: { type: Function, required: true },
    title: { type: String, required: true },
  },
}
</script>

<style lang="scss" scoped>
.button {
  background: #ff8300;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  padding: 10px;
}
</style>

