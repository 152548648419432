import Router from 'vue-router'
import Home from './pages/Home.vue'
import Details from './pages/Details.vue'

const router = new Router({
  base: process.env.FEATURE_BRANCH_ROUTER_BASE
    ? `/${process.env.FEATURE_BRANCH_ROUTER_BASE}/`
    : '/',
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
    },
    {
      path: '/details/:id',
      name: 'details',
      component: Details,
    },
  ],
})
export default router
