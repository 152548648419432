
<template>
  <div>
    <h3>This is Details Page</h3>
    <Button @click.native="goToHomePage" title="Back to Home" />
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  name: 'Details',
  components: {
    Button,
  },
  methods: {
    goToHomePage: function () {
      this.$router.push('/home')
    },
  },
}
</script>

